import { useState } from 'react';
import './SubscribeForm.css';
import Loading from '../Loading/Loading.js';


function SubscribeForm({ setIsSuccessfulPost, interest, setInterest }) {

    const [lastName, setLastName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [email, setEmail] = useState("");
    const [isAccepted, setIsAccepted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    const isEnabled = lastName !== "" && firstName !== "" && email !== "" && isAccepted;
    const apiUrl = process.env.REACT_APP_API_ENDPOINT;
    console.log("apiURL", process.env.REACT_APP_API_ENDPOINT);

    const userData = {
        lastName: lastName,
        firstName: firstName,
        email: email,
        interest: interest
    }

    async function postUserData() {
        setIsLoading(true);
        try {
            const response = await fetch(apiUrl, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(userData)
            });
            console.log(response)
            if (response.status === 201) {
                setIsSuccessfulPost(true);
            } else {
                setError("error")
            }
        }
        catch {
            setError("error")      //network error - no response, no status
        }
        setIsLoading(false);
    }


    return (
        <div className="SubscribeForm">
            <div className="SubscribeForm-header">Hamarosan jövünk... </div>
            <div className="SubscribeForm-title">Feliratkozás a várólistára</div>
            <input type="text" placeholder="Vezetéknév *" value={lastName} onChange={e => setLastName(e.target.value)} required />
            <input type="text" placeholder="Keresztnév *" value={firstName} onChange={e => setFirstName(e.target.value)} required />
            <input type="email" placeholder="E-mail *" value={email} onChange={e => setEmail(e.target.value)} required />
            <select value={interest} onChange={e => setInterest(e.target.value)}>
                <option value="" > Válaszd ki az érdeklődésedet: </option>
                <option value="sell">Értékpapír eladás</option>
                <option value="buy">Értékpapír vétel</option>
            </select>
            <div className="SubscribeForm-dataCheckbox" >
                <label>
                    <input type="checkbox" value={isAccepted} onChange={() => setIsAccepted(current => !current)} />
                    Hozzájárulok, hogy az allampapircsere.hu csapata megkeressen e-mailben.
                </label>
            </div>
            {isLoading? <Loading/> : <button
                className="SubscribeForm-btn"
                disabled={!isEnabled}
                onClick={() => postUserData()}
            >
                Feliratkozás
            </button>}
            {error === "error" && <div class="SubscribeForm-tryAgain">Hiba történt, próbáld újra!</div>}
        </div>
    )
}

export default SubscribeForm;