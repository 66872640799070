import { useRef, useState } from 'react';
import useIntersectionObserver from '@react-hook/intersection-observer';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import PieChartIcon from '@mui/icons-material/PieChart';
import SellIcon from '@mui/icons-material/Sell';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ChairIcon from '@mui/icons-material/Chair';
import './BenefitPage.css';

function BenefitPage({ refProps }) {

    const pageRef = useRef(0);          //manipulate the DOM - this component
    const { isIntersecting } = useIntersectionObserver(pageRef); 
    const [ visited, setVisited ] = useState(false);   //animation only one time for a visitor

    if (isIntersecting && !visited) {
        setVisited(true);
    }

    const benefits = [
        { name: "Hozamelőny", icon: <TrendingUpIcon/>, details: "Csapj le a legjobb megtakarítási lehetőségekre, vagy add el állampapírjaidat a kincstári árfolyamnál kedvezőbb áron." },
        { name: "Megkötés nélkül", icon: <PieChartIcon/>, details: "Határozd meg a saját minimális vételi és eladási mennyiséged."},
        { name: "Szabad árazás", icon: <SellIcon/>, details: "Böngéssz az eladásra kínált állampapírok között." },
        { name: "Biztonság", icon: <VerifiedUserIcon/>, details: "Online szerződéskötés és fizetés, azonnali teljesítéssel." },
        { name: "Kényelem", icon: <ChairIcon/>, details: "Otthonról vagy útközben, amikor és ahol éppen aktuális" }
    ]

    return (
        <div className="BenefitPage" ref={refProps}>
            <div className="BenefitPage-title">Előnyök</div>
            <div className="BenefitPage-subtitle">Mindenki jobban jár</div>
            <div className="BenefitPage-benefitsContainer" ref={pageRef}>
                {benefits.map((benefit, index) => {
                    return (
                        <div
                            className={`${!visited && 'BenefitPage-benefitCard-hidden'} BenefitPage-benefitCard`}
                            style={{ transitionDelay: `${index * 0.2}s` }}
                        >
                            <div className="BenefitPage-benefitCardName"><span>{benefit.icon} </span> {benefit.name}</div>
                            <div className="BenefitPage-benefitCardDetails">{benefit.details}</div>
                        </div>
                    )
                    
                })}
            </div>
        </div>
    )

}

export default BenefitPage