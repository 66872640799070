import './App.css';
import { useRef } from 'react';
import { useState } from 'react';
import LandingPage from '../LandingPage/LandingPage.js'
import BenefitPage from '../BenefitPage/BenefitPage.js';
import BenefitPage2 from '../BenefitPage2/BenefitPage2.js'
import SubscribePage from '../SubscribePage/SubscribePage.js'
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary.js';


function App() {

  const [interest, setInterest] = useState("");

  const benefitPageRef = useRef();
  const subscribePageRef = useRef();
  const scrollToBenefitPage = () => {
    benefitPageRef.current.scrollIntoView({ behavior: 'smooth' })
 }

 const scrollToSubscribePage = () => {
  subscribePageRef.current.scrollIntoView({ behavior: 'smooth' })
}

  return (
    <ErrorBoundary fallback={<p>Something went wrong</p>}>
    <div className="App">
      <LandingPage scrollToBenefitPage={scrollToBenefitPage} scrollToSubscribePage={scrollToSubscribePage}/>
      <BenefitPage refProps={benefitPageRef} />
      <BenefitPage2 scrollToSubscribePage={scrollToSubscribePage} setInterest={setInterest}/>
      <SubscribePage refProps={subscribePageRef} interest={interest} setInterest={setInterest}/>
    </div>
    </ErrorBoundary>
  );
}

export default App;
