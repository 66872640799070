import OrderBook from '../OrderBook/OrderBook';
import './LandingPage.css';

function LandingPage({ scrollToBenefitPage, scrollToSubscribePage }) {
    return (
        <div className="LandingPage">
            <div className="LandingPage-header">
                <div className="LandingPage-name">Állampapír Csere</div>
                <div className="LandingPage-links">
                    <div onClick={() => scrollToBenefitPage()} className="LandingPage-benefitLink">Amit ajánlunk</div>
                    <div onClick={() => scrollToSubscribePage()}>Feliratkozás</div>
                </div>
            </div>
            <div className="LandingPage-content">
                <div className="LandingPage-title">Magyar Állampapír Csere Platform</div>
                <div className="LandingPage-subTitle">Magasabb hozam mindenkinek</div>
                <div className="LandingPage-orderBook">
                  <OrderBook></OrderBook>
                </div>
            </div>

        </div>
    )
}

export default LandingPage
