import { useState } from 'react';
import { useInterval } from 'usehooks-ts';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import OrderListItem from '../OrderListItem/OrderListItem';
import './OrderBook.css';


/**
* @type {import('../OrderListItem/OrderListItem').Order[]}
*/
const initialOrders = [];
for (let i = 0; i < 10; i++) {
  initialOrders.push(generateRandomOrder());
}

export default function OrderBook() {
  const [ orders, setOrders ] = useState([generateRandomOrder(), ...initialOrders]);

  useInterval(() => setOrders([generateRandomOrder(), ...orders]), 5_000);

  return (
    <TransitionGroup component="div" className="OrderBook">
      {orders.slice(0, 10).map(order =>
        <CSSTransition key={order.id} timeout={1500} classNames="OrderListItem">
          <OrderListItem {...order}></OrderListItem>
        </CSSTransition>
      )}
    </TransitionGroup>
  );
}

/**
 *
 * @returns {import('../OrderListItem/OrderListItem').Order}
 */
function generateRandomOrder() {
  return {
    id: window.crypto.randomUUID(),
    amount: Math.ceil(Math.random() * 100) * 1_000_000,
    currency: 'huf',
    orderType: 'sell',
    bondType: pickRandom(['PMÁP', 'MÁK', 'BMÁP']),
    bondSeries: pickRandom(['2031/I', '2033/K', '2027/L']),
  }
}

function pickRandom(list) {
  const index = Math.floor(Math.random() * list.length);
  return list[index];
}
