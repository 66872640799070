import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import BondIcon from '../BondIcon/BondIcon';
import './OrderListItem.css'

/**@typedef {'PMÁP'|'BMÁP'|'MÁK'} BondType */

/**
 * @typedef {Object} Order
 * @property {string} id
 * @property {'buy'|'sell'} orderType
 * @property {BondType} bondType
 * @property {string} bondSeries
 * @property {number} amount
 * @property {'huf'|'eur'} currency
 */

/**
 *
 * @param {Order} param0
 * @returns
 */
export default function OrderListItem ({ orderType, bondType, bondSeries, amount, currency }) {


  return (
    <div className="OrderListItem">
      <div className="OrderListItem-icon">
        <BondIcon
          bondType={bondType}
          size={40}
        ></BondIcon>
      </div>
      {/* <div>{orderType === 'buy' ? 'keres' : 'kínál' }</div> */}
      <div>{bondType} {bondSeries}</div>
      <div>
        <span className="OrderListItem-nowrap">{getInterest(bondType, bondSeries)}%</span>
        <span> </span>
        <span className="OrderListItem-nowrap">{getDuration(bondType, bondSeries)}</span>
      </div>
      <div className="OrderListItem-gap"></div>

      <div className="OrderListItem-price">
        <span className="OrderListItem-amount">{amount / 1000000}M </span>
        <span>{currency === 'huf' ? 'Ft' : '€'}</span>
      </div>
      <div className="OrderListItem-action">
        <ShoppingCartIcon></ShoppingCartIcon>
      </div>
    </div>
  );
}

/**
 *
 * @param {BondType} bondType
 * @param {series} bondSeries
 * @returns {number}
 */
function getInterest(bondType, bondSeries) {
  const interests = {
    'BMÁP 2030/J': 12.1,
    'PMÁP 2032/I': 4.3,
  }
  return interests[`${bondType} ${bondSeries}`] || 4.02;
}

function getDuration(bondType, bondSeries) {
  return '3 év 4 hónap';
}
