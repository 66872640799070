import { useState } from 'react';
import SubscribeForm from '../SubscribeForm/SubscribeFormjs.js';
import SuccessfulPostPage from '../SuccessfulPostPage/SuccessfulPostPage.js';
import './SubscribePage.css';

function SubscribePage({ refProps, interest, setInterest }){

    const [isSuccessfulPost, setIsSuccessfulPost] = useState(false);

    return(
        <div className="SubscribePage" ref={refProps}>
        {isSuccessfulPost === false &&
          <div className="SubscribePage-content">
            <img src="/financial_image.jpeg" className="SubscribePage-image" alt="financial" />
            <SubscribeForm setIsSuccessfulPost={setIsSuccessfulPost} interest={interest} setInterest={setInterest}/>
          </div>}
        {isSuccessfulPost && <SuccessfulPostPage />}
      </div>
    )
}

export default SubscribePage;