import './BenefitPage2.css';

function BenefitPage2({scrollToSubscribePage, setInterest}) {

    function onClickSellButton(){
        scrollToSubscribePage()
        setInterest("sell");
    }

    function onClickBuyButton(){
        scrollToSubscribePage()
        setInterest("buy");
    }

    return (
        <div className="BenefitPage2">
            <div className="BenefitPage2-content">
                <div className="BenefitPage2-textContainer">
                    <div className="BenefitPage2-title">Tegyél ajánlatot! Készen állsz?</div>
                    <div>Szabadulj meg az Államkincstár 1%-os visszaváltási költségétől és piaci áron adhatod el az állampapírodat.</div>
                    <button className="BenefitPage2-sellButton" onClick={() => onClickSellButton()}>Kezdd el az eladást</button>
                    <div>Várásolj korábban kibocsátott állampapírt az Államkincstárnál jelenleg elérhető lehetőségeknél jobb kondíciókkal.</div>
                    <button className="BenefitPage2-buyButton" onClick={() => onClickBuyButton()}>Kezdd el a vásárlást</button>
                </div>
                <img src="/iphone.png" className="BenefitPage2-image" alt="iphone" />
            </div>
        </div>
    )
}

export default BenefitPage2;