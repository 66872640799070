export default function BondIcon({ size, bondType }) {
  const colors = {
    'PMÁP': '#00b0b9',
    'BMÁP': '#c0d600',
    'MÁK': '#ff8200',
  };
  return (
    <div style={{
      width: `${size}px`,
      height: `${size}px`,
      paddingLeft: `${(size - 28) / 2}px`,
      paddingTop: `${(size - 31) / 2}px`,
      backgroundColor: colors[bondType],
      borderRadius: `${size/8}px`,
    }}>
      <svg width="28" height="31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.6.5h-.57L7.59 25.98l-1.27.17v.38h3.3v-.38l-1.27-.17 5.68-19.93 6.15 19.93-1.08.17v.38h5.58v-.38l-1.07-.17L15.6.5ZM3.09 7.81l5 13.06 1.53-5.15L5.37 4.57H.25v.38l2.07.36v16.74l-1.26.14v.38h3.29v-.38l-1.26-.14V7.8Zm9.88 13.06h4.63l.23.75h-.13l-.15.01H16.2v8.32l1.07.17v.38H11.9v-.38l1.07-.17v-9.08ZM24.87 6.9a8.95 8.95 0 0 0-3.1-1.8 12.58 12.58 0 0 0-4.06-.64l.24.77c1.81.08 3.23.68 4.25 1.82 1.1 1.22 1.64 3.22 1.64 5.99s-.54 4.77-1.64 5.99l-.05.05.06.19.24.77.18.59a8.5 8.5 0 0 0 2.24-1.45 7.7 7.7 0 0 0 1.93-2.7c.45-1.05.67-2.2.67-3.44 0-1.25-.23-2.4-.67-3.43a7.72 7.72 0 0 0-1.93-2.71ZM14.02.5l-1.16 4.15h-.1L12.34.5h1.68Z"
          fill="white"/>
      </svg>
    </div>
  );
}
